<template>
  <div v-if="canAccess('cobranzas_cuentas')">
    <Breadcrumb
        :home="breadcrumb.home"
        :model="breadcrumb.items"
        class="card"
    />

    <div class="p-fluid p-grid tags">
      <div class="p-fluid p-col-fixed p-mx-1">
        <div class="p-fluid card p-grid p-col-fixed card-body">
          <span class="p-col-fixed card-summary p-px-0"> Cobradas </span>
          <div class="p-col-12 p-px-0">
            <span class="tag-yellow">
              {{ counter?.account_payment ?? 0 }}
            </span>
          </div>
        </div>
      </div>
      <div class="p-fluid p-col-fixed p-mx-1">
        <div class="p-fluid card p-grid p-col-fixed card-body">
          <span class="p-col-fixed card-summary p-px-0"> Anticipadas </span>
          <div class="p-col-12 p-px-0">
            <span class="tag-yellow">
              {{ counter?.account_anticipe ?? 0 }}
            </span>
          </div>
        </div>
      </div>
      <div class="p-fluid p-col-fixed p-mx-1">
        <div class="p-fluid card p-grid p-col-fixed card-body">
          <span class="p-col-fixed card-summary p-px-0"> Vigencia Actual </span>
          <div class="p-col-12 p-px-0">
            <span class="tag-green">{{ counter?.vigence ?? 0 }}</span>
          </div>
        </div>
      </div>
      <div class="p-fluid p-col-fixed p-mx-1">
        <div class="p-fluid card p-grid p-col-fixed card-body">
          <span class="p-col-fixed card-summary p-px-0"> Por cobrar </span>
          <div class="p-col-12 p-px-0">
            <span class="tag-yellow">
              {{ counter?.account_without_payment ?? 0 }}
            </span>
          </div>
        </div>
      </div>
      <div class="p-fluid p-col-fixed p-mx-1">
        <div class="p-fluid card p-grid p-col-fixed card-body">
          <span class="p-col-fixed card-summary p-px-0">
            Porcentaje recaudado
          </span>
          <div class="p-col-12 p-px-0">
            <span class="tag-green"> {{ counter?.percentage_rec ?? 0 }}% </span>
          </div>
        </div>
      </div>
      <div class="p-fluid p-col-fixed p-mx-1">
        <div class="p-fluid card p-grid p-col-fixed card-body">
          <span class="p-col-fixed card-summary p-px-0"> Monto asignado </span>
          <div class="p-col-12 p-px-0">
            <span class="tag-yellow">
              ${{ counter?.amount_assignment ?? 0 }}
            </span>
          </div>
        </div>
      </div>
      <div class="p-fluid p-col-fixed p-mx-1">
        <div class="p-fluid card p-grid p-col-fixed card-body">
          <span class="p-col-fixed card-summary p-px-0"> Monto recaudado </span>
          <div class="p-col-12 p-px-0">
            <span class="tag-blue">
              ${{ counter?.amount_paymented ?? 0 }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="card p-mx-0">
      <div class="p-field p-fluid p-col-12">
        <ProgressBar
            mode="indeterminate"
            v-if="loading"
            class="p-progressbar"
        />
      </div>
      <div class="p-fluid p-grid inputs">
        <div class="p-field p-fluid p-col-12 p-sm-4 p-md-3 p-xl-2">
          <span class="p-float-label">
            <InputText id="enroll" v-model="search" @keyup.enter="filter()" />
            <label class="p-label" for="enroll">Buscar por</label>
          </span>
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-4 p-md-3 p-xl-2">
          <span class="p-float-label">
            <Calendar
                id="dateInit"
                :showIcon="true"
                v-model="dateInit"
                :dateFormat="dateFormatsDDMMYY"
                :yearNavigator="true"
                :yearRange="yearRangeDateInitAt"
                :maxDate="dateFinish"
                @date-select="filterEvaluateDates"
            />
            <label class="p-label" for="dateInit">Fecha Desde</label>
          </span>
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-4 p-md-3 p-xl-2">
          <span class="p-float-label">
            <Calendar
                id="dateFinish"
                :showIcon="true"
                v-model="dateFinish"
                :dateFormat="dateFormatsDDMMYY"
                :yearNavigator="true"
                :yearRange="yearRangeDateFinishAt"
                :maxDate="dateFinishMax"
                @date-select="filterEvaluateDates"
                :minDate="dateInit"
            />
            <label class="p-label" for="dateFinish">Fecha Hasta</label>
          </span>
        </div>
        <div
            class="p-field p-fluid p-col-12 p-sm-4 p-md-3 p-xl-2"
            v-if="isAdmin"
        >
          <span class="p-float-label">
            <Dropdown
                id="country"
                v-model="country"
                :options="countries"
                optionLabel="country"
                @change="
                filter();
                subsidiary = null;
              "
                :show-clear="true"
                :filter="true"
            >
              <template #value="slotProps">
                <div class="size-dropdown-value p-label">
                  <span class="p-label size-slot-props-value">
                    {{ slotProps?.value?.country }}
                  </span>
                </div>
              </template>
              <template #option="slotProps">
                <div class="p-column-option-value">
                  {{ slotProps.option.country }}
                </div>
              </template>
            </Dropdown>
            <label class="p-label" for="country">País</label>
          </span>
        </div>
        <div
            class="p-field p-fluid p-col-12 p-sm-4 p-md-3 p-xl-2"
            v-if="canAccess('cobranzas_admin')"
        >
          <span class="p-float-label">
            <Dropdown
                id="subsidiary"
                v-model="subsidiary"
                :options="subsidiaries"
                optionLabel="name"
                @change="filter"
                :show-clear="true"
                :filter="true"
                class="subsidiary-dropdown-size"
            >
              <template #value="slotProps">
                <div class="size-dropdown-value p-label">
                  <span class="p-label size-slot-props-value">{{
                      slotProps?.value?.name
                    }}</span>
                </div>
              </template>
              <template #option="slotProps">
                <div class="p-column-option-value">
                  {{ slotProps.option.name }}
                </div>
              </template>
            </Dropdown>
            <label class="p-label" for="subsidiary">Filial</label>
          </span>
        </div>
        <div
            class="p-field p-fluid p-col-12 p-sm-4 p-md-3 p-xl-2"
            v-if="canAccess('cobranzas_admin')"
        >
          <span class="p-float-label">
            <Dropdown
                id="executive_account"
                v-model="executiveAccount"
                :options="executives"
                optionLabel="executive"
                @change="filter"
                :show-clear="true"
                :filter="true"
                class="executives-dropdown-size"
            >
              <template #value="slotProps">
                <div class="size-dropdown-value p-label">
                  <span class="p-label size-slot-props-value">{{
                      slotProps?.value?.executive
                    }}</span>
                </div>
              </template>
              <template #option="slotProps">
                <div class="p-column-option-value">
                  {{ slotProps.option.executive }}
                </div>
              </template>
            </Dropdown>
            <label class="p-label" for="executive_account">Ejecutivo</label>
          </span>
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-4 p-md-3 p-xl-2">
          <span class="p-float-label">
            <Dropdown
                id="day_payment"
                v-model="day_payment"
                :options="daysPayments"
                optionLabel="day"
                @change="filter"
                :show-clear="true"
                :filter="true"
                class="day-payment-dropdown-size"
            >
              <template #value="slotProps">
                <div class="size-dropdown-value p-label">
                  {{ slotProps?.value?.day }}
                </div>
              </template>
              <template #option="slotProps">
                <div class="p-column-option-value">
                  {{ slotProps.option.day }}
                </div>
              </template>
            </Dropdown>
            <label class="p-label" for="day_payment">Día Cobro</label>
          </span>
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-4 p-md-3 p-xl-2">
          <span class="p-float-label">
            <MultiSelect
                id="typeAccount"
                v-model="typeAccount"
                :options="typeAccounts"
                :filter="true"
                display="chip"
                @change="filter"
                style="height: 2.2925rem"
            >
              <template #value="slotProps">
                <div v-if="slotProps.value && slotProps.value.length > 0">
                  <span>
                    <span :key="i" v-for="(item, i) in slotProps.value">
                      {{ item + ", " }}
                    </span>
                  </span>
                </div>
                <div class="size-dropdown-value" v-else>
                  {{ slotProps.placeholder }}
                </div>
              </template>
              <template #option="slotProps">
                <div class="p-column-option-value">
                  {{ slotProps.option }}
                </div>
              </template>
            </MultiSelect>
            <label class="p-label" for="typeAccount"> Tipo Cuenta </label>
          </span>
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-4 p-md-3 p-xl-2">
          <span class="p-float-label">
            <Calendar
                id="dateCobInit"
                :showIcon="true"
                v-model="dateCobInit"
                :dateFormat="dateFormatsDDMMYY"
                :yearNavigator="true"
                :yearRange="yearRangeInitialPaymentDateAt"
                :maxDate="dateCobFinish"
                @date-select="filterCobEvaluateDates"
            />
            <label class="p-label" for="dateCobInit">Fecha Cobro Desde</label>
          </span>
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-4 p-md-3 p-xl-2">
          <span class="p-float-label">
            <Calendar
                id="dateCobFinish"
                :showIcon="true"
                v-model="dateCobFinish"
                :dateFormat="dateFormatsDDMMYY"
                :yearNavigator="true"
                :yearRange="yearRangeFinishPaymentDateAt"
                @date-select="filterCobEvaluateDates"
                :minDate="dateCobInit"
            />
            <label class="p-label" for="dateCobFinish">Fecha Cobro Hasta</label>
          </span>
        </div>
        <div class="p-field p-fluid p-col-12 p-sm-4 p-md-3 p-xl-2">
          <span class="p-float-label">
            <Dropdown
                id="current_fee_status"
                v-model="current_fee_status"
                :options="statusFees"
                @change="filter"
                :show-clear="true"
                :filter="true"
                class="current-fee-status-dropdown-size"
            >
              <template #value="slotProps">
                <div class="size-dropdown-value p-label">
                  <span class="p-label size-slot-props-value">{{
                      slotProps?.value
                    }}</span>
                </div>
              </template>
              <template #option="slotProps">
                <div class="p-column-option-value">
                  {{ slotProps.option }}
                </div>
              </template>
            </Dropdown>
            <label class="p-label" for="dateFinish">Estado Cuota Vigente</label>
          </span>
        </div>
      </div>
      <div class="p-fluid p-grid p-jc-end">
        <div class="p-field p-fluid p-col-fixed btns-for-search">
          <Button label="Limpiar" class="p-button-secondary" @click="reload" />
        </div>
        <div class="p-field p-fluid p-col-fixed btns-for-search">
          <Button
              label="Descargar"
              class="p-button-primary"
              @click="downloadReport"
          />
        </div>
      </div>
    </div>
    <div class="card p-mx-0">
      <div class="p-fluid p-grid">
        <DataTable
            :value="myEnrollments"
            :paginator="true"
            dataKey="sequential"
            class="p-datatable-responsive-demo p-datatable-sm"
            :rowClass="getColorClass"
            v-model:selection="currentItem"
            selectionMode="single"
            @row-select="selectData($event)"
            paginatorPosition="bottom"
            :totalRecords="page.total"
            :rows="page.per_page"
            :loading="loading"
            @page="onPage($event)"
            v-model:expandedRows="expandedRows"
        >
          <Column :expander="true" headerStyle="width: 3rem" />
          <Column field="sequential" header="No. Matrícula" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">No. Matrícula</span>
              <router-link
                  class="p-column-value"
                  :class="getColorClass(slotProps.data)"
                  :to="
                  '/raise-money/detail-payments/' +
                  b64EncodeUnicode(slotProps.data.sequential) +
                  '/' +
                  slotProps.data.processed
                "
              >
                {{ slotProps.data.sequential }}
              </router-link>
            </template>
          </Column>
          <Column
              field="exec_account"
              header="Ejecutivo Cuenta"
              :sortable="true"
          >
            <template #body="slotProps">
              <span class="p-column-title">Ejecutivo Cuenta</span>
              <span
                  class="p-column-value"
                  :class="getColorClass(slotProps.data)"
              >
                {{ slotProps.data?.exec_account }}
              </span>
            </template>
          </Column>
          <Column
              field="contract.holder.dni"
              header="Cédula Titular"
              :sortable="true"
          >
            <template #body="slotProps">
              <span class="p-column-title">Cédula Titular</span>
              <span
                  class="p-column-value"
                  :class="getColorClass(slotProps.data)"
              >
                {{ slotProps.data.contract?.holder?.dni }}
              </span>
            </template>
          </Column>
          <Column
              field="contract.holder.name"
              header="Nombre Titular"
              :sortable="true"
          >
            <template #body="slotProps">
              <span class="p-column-title">Nombre Titular</span>
              <span
                  class="p-column-value"
                  :class="getColorClass(slotProps.data)"
              >
                {{ slotProps.data.contract?.holder?.name }}
                {{ slotProps.data.contract?.holder?.last_name }}
              </span>
            </template>
          </Column>
          <Column
              field="type_enrollment"
              header="Tipo"
              :sortable="true"
              headerStyle="width: 10rem"
          >
            <template #body="slotProps" style="width: 20rem">
              <span class="p-column-title">Tipo</span>
              <Button
                  v-if="slotProps.data.is_update.length > 0"
                  @click="viewStatus(slotProps.data.is_update, slotProps.data)"
                  class="p-button-info p-button-text p-column-value p-p-0 p-text-left"
              >
                <span :class="getColorClass(slotProps.data)" class="type-enrollment-text">
                  {{ slotProps.data.type_enrollment }}
                </span>
              </Button>
              <div v-else class="p-column-value p-text-left">
                {{ slotProps.data.type_enrollment }}
              </div>
            </template>
          </Column>
          <Column field="balancerst" header="Saldo" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Saldo</span>
              <span
                  class="p-column-value"
                  :class="getColorClass(slotProps.data)"
              >
                {{ formatMoney(slotProps.data.balancerst ?? 0) }}
              </span>
            </template>
          </Column>
          <Column field="totalplan" header="Valor Total" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Valor Total</span>
              <span
                  class="p-column-value"
                  :class="getColorClass(slotProps.data)"
              >
                {{ formatMoney(slotProps.data.totalplan ?? 0) }}
              </span>
            </template>
          </Column>
          <Column field="amount_fee" header="Valor Cuota" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Valor Cuota</span>
              <span
                  class="p-column-value"
                  :class="getColorClass(slotProps.data)"
              >
                {{ formatMoney(slotProps.data.amount_fee) }}
              </span>
            </template>
          </Column>
          <Column
              field="amount_paid_vigence"
              header="Monto Cobrado"
              :sortable="true"
          >
            <template #body="slotProps">
              <span class="p-column-title">Monto Cobrado</span>
              <span
                  class="p-column-value"
                  :class="getColorClass(slotProps.data)"
              >
                {{ formatMoney(slotProps.data.amount_paid_vigence) }}
              </span>
            </template>
          </Column>
          <Column
              field="amount_paid_anticipe"
              header="Monto Anticipado"
              :sortable="true"
          >
            <template #body="slotProps">
              <span class="p-column-title">Monto Anticipado</span>
              <span
                  class="p-column-value"
                  :class="getColorClass(slotProps.data)"
              >
                {{ formatMoney(slotProps.data.amount_paid_anticipe ?? 0) }}
              </span>
            </template>
          </Column>
          <Column
              field="plan.total_value"
              header="Porcentaje Recaudo"
              :sortable="true"
          >
            <template #body="slotProps">
              <span class="p-column-title">Porcentaje Recaudo</span>
              <span
                  class="p-column-value"
                  :class="getColorClass(slotProps.data)"
              >
                {{
                  parseFloat(
                      (slotProps.data.paymented * 100) /
                      slotProps.data.plan?.total_value ?? 1
                  ).toFixed(2)
                }}%
              </span>
            </template>
          </Column>
          <Column
              field="contract.date_first_payment"
              header="Día Cobro"
              :sortable="true"
          >
            <template #body="slotProps">
              <span class="p-column-title">Día Cobro</span>
              <span
                  class="p-column-value"
                  :class="getColorClass(slotProps.data)"
              >
                {{ getDay(slotProps.data?.contract?.date_first_payment) }}
              </span>
            </template>
          </Column>
          <Column
              field="date_agreement"
              header="Día acordado pago"
              :sortable="true"
          >
            <template #body="slotProps">
              <span class="p-column-title">Día acordado pago</span>
              <span
                  class="p-column-value"
                  :class="getColorClass(slotProps.data)"
              >
                {{
                  slotProps.data?.processeds?.agreements?.date_agreement ?? "-"
                }}
              </span>
            </template>
          </Column>
          <Column
              field="current_status_fee"
              header="Estado Cuota Vigente"
              :sortable="true"
          >
            <template #body="slotProps">
              <span class="p-column-title">Estado Cuota Vigente</span>
              <span
                  class="p-column-value"
                  :class="getColorClass(slotProps.data)"
              >
                {{ slotProps.data.current_status_fee ?? "-" }}
              </span>
            </template>
          </Column>
          <template #expansion="slotProps">
            <div class="orders-subtable" v-if="slotProps.data.payments">
              <h5>Pagos de matrícula</h5>
              <DataTable
                  :value="slotProps.data.payments"
                  row-hover="false"
                  dataKey="id"
                  class="p-datatable-responsive-demo p-datatable-sm"
              >
                <Column
                    field="type_payment"
                    header="Tipo de pago"
                    :sortable="true"
                >
                  <template #body="slotSubProps">
                    <span class="p-column-title">Tipo de pago</span>
                    <span class="p-column-value">
                      {{ getTypePayment(slotSubProps.data.type_payment) }}
                    </span>
                  </template>
                </Column>
                <Column field="status" header="Estado" :sortable="true">
                  <template #body="slotSubProps">
                    <span class="p-column-title">Estado</span>
                    <span class="p-column-value">
                      {{ getTypePayment(slotSubProps.data.status) }}
                    </span>
                  </template>
                </Column>
                <Column
                    field="type_transaction"
                    header="Tipo de transacción"
                    :sortable="true"
                >
                  <template #body="slotSubProps">
                    <span class="p-column-title">Tipo de transacción</span>
                    <span class="p-column-value">
                      {{ getTypePayment(slotSubProps.data.type_transaction) }}
                    </span>
                  </template>
                </Column>
                <Column field="value" header="Valor" :sortable="true">
                  <template #body="slotSubProps">
                    <span class="p-column-title">Valor</span>
                    <span class="p-column-value">
                      {{ formatMoney(slotSubProps.data.value) }}
                    </span>
                  </template>
                </Column>
                <Column
                    field="value_paid"
                    header="Valor Pagado"
                    :sortable="true"
                >
                  <template #body="slotSubProps">
                    <span class="p-column-title">Valor Pagado</span>
                    <span class="p-column-value">
                      {{ formatMoney(slotSubProps.data.value_paid) }}
                    </span>
                  </template>
                </Column>
                <Column
                    field="date_payment"
                    header="Fecha de pago"
                    :sortable="true"
                >
                  <template #body="slotSubProps">
                    <span class="p-column-title">Fecha de pago</span>
                    <span
                        class="p-column-value"
                        v-if="slotSubProps.data.date_payment"
                    >
                      {{ $filters.dateFormat(slotSubProps.data.date_payment) }}
                    </span>
                  </template>
                </Column>
                <template #empty> No se encontraron registros. </template>
              </DataTable>
            </div>
          </template>
          <template #empty> No se encontraron registros. </template>
        </DataTable>
      </div>
    </div>
    <Dialog
        v-model:visible="dialogType"
        header="Cambio de Tipo Cuenta"
        :modal="true"
    >
      <div class="p-fluid p-pt-5 size-dialog">
        <Dropdown
            v-model="dialogTypeSelected"
            :options="dialogTypeOptions"
            style="width: 100%"
            :show-clear="true"
            :filter="true"
        >
          <template #value="slotProps">
            <div class="size-dropdown-value">
              <span class="p-label size-slot-props-value">
                {{ slotProps?.value }}
              </span>
            </div>
          </template>
          <template #option="slotProps">
            <div class="p-column-option-value">
              {{ slotProps.option }}
            </div>
          </template>
        </Dropdown>
      </div>
      <template #footer>
        <div class="p-field p-fluid p-col-12">
          <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-text"
              @click="dialogType = false"
          />
          <Button
              label="Guardar"
              icon="pi pi-check"
              class="p-button-text"
              @click="changeType"
          />
        </div>
      </template>
    </Dialog>
  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>¡ATENCIÓN!</b></h5>
    <div class="card-body">
      <p class="card-title">
        El acceso a esta opción no se encuentra autorizado
      </p>
      <Button
          label="Regresar"
          class="p-button-danger p-mr-2"
          @click="$router.push('/admin')"
      />
    </div>
  </div>
</template>
<script src="./MyEnrollmentsList.js"></script>
<style src="./MyEnrollmentsList.scss" lang="scss" scoped></style>
