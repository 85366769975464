import { mapGetters, mapState } from "vuex";
import moment from "moment";
import {
  dateFormats,
  initialEnrollmentColorPoints,
  myEnrollmentsReport,
  typeEnrollmentForClassCss,
  typeNotification,
  typePayment,
  yearRangeDateFinishMyEnrollment,
  yearRangeDateInitMyEnrollment,
  yearRangeFinishPaymentDateEnrollment,
  yearRangeInitialPaymentDateEnrollment,
} from "@/helpers/consts";
import bouncer from "@/helpers/bouncer";
import monthsWeeksService from "@/modules/core/service/mw.service";
import raiseMoneyService from "@/modules/core/service/raiseMoney.service";

export default {
  mixins: [bouncer],
  name: "RaiseMoneyList",
  props: {
    enroll: Object,
    edit: Boolean,
  },
  components: {},
  data() {
    return {
      current_fee_status: null,
      typeAccount: null,
      itemSelect: null,
      dialogType: false,
      dialogTypeSelected: null,
      dialogTypeOptions: [],
      day_payment: null,
      pageInit: {
        page: 1,
        per_page: 25,
        total: 0,
        order: "desc",
      },
      page: {
        page: 1,
        per_page: 25,
        total: 0,
        order: "desc",
      },
      counter: null,
      search: "",
      dateFinish: "",
      dateFinishMax: "",
      dateInit: "",
      dateCobInit: "",
      dateCobFinish: "",
      myEnrollments: [],
      loading: false,
      currentItem: null,
      params: {},
      country: null,
      subsidiary: null,
      executiveAccount: null,
      countries: [],
      expandedRows: [],
      typeAccounts: [
        "Opcional",
        "Opcional por Novedad",
        "Primera Cuota",
        "Obligatoria",
        "Anticipada",
      ],
      statusFees: [
        "NO COBRADO",
        "PARCIALMENTE NO COBRADO",
        "PARCIALMENTE COBRADO",
        "COBRADO",
        "ANTICIPADA",
      ],
      dateFormatsYYYYMMDD: dateFormats.YYYYMMDD,
      dateFormatsDDMMYY: dateFormats.DDMMYYYY,
      yearRangeDateInitAt: yearRangeDateInitMyEnrollment,
      yearRangeDateFinishAt: yearRangeDateFinishMyEnrollment,
      yearRangeInitialPaymentDateAt: yearRangeInitialPaymentDateEnrollment,
      yearRangeFinishPaymentDateAt: yearRangeFinishPaymentDateEnrollment,
    };
  },
  methods: {
    notification(severity, summary, detail) {
      this.$toast.add({
        severity: severity,
        summary: summary,
        detail: detail,
        life: this.$utils.toastLifeTime(),
      });
    },
    getColorClass(myEnrollment) {
      let classCss = null;
      if (
          myEnrollment.type_enrollment === typeEnrollmentForClassCss.optional.name
      ) {
        classCss = typeEnrollmentForClassCss.optional.classCss;
      }
      if (
          myEnrollment.type_enrollment ===
          typeEnrollmentForClassCss.optionalByNovelty.name
      ) {
        classCss = typeEnrollmentForClassCss.optionalByNovelty.classCss;
      }
      if (
          myEnrollment.type_enrollment ===
          typeEnrollmentForClassCss.firstInstallment.name
      ) {
        classCss = typeEnrollmentForClassCss.firstInstallment.classCss;
      }
      if (
          myEnrollment.type_enrollment ===
          typeEnrollmentForClassCss.mandatory.name
      ) {
        classCss = typeEnrollmentForClassCss.mandatory.classCss;
      }
      if (
          myEnrollment.type_enrollment === typeEnrollmentForClassCss.advance.name
      ) {
        classCss = typeEnrollmentForClassCss.advance.classCss;
      }

      if (
          myEnrollment.enrollment_color?.points ===
          initialEnrollmentColorPoints &&
          myEnrollment.enrollment_color?.pay_actually !==
          typeEnrollmentForClassCss.colorPayActually.name
      ) {
        classCss = typeEnrollmentForClassCss.colorPayActually.classCss;
      } else {
        if (
            myEnrollment.enrollment_color?.monto_pagado >=
            myEnrollment.enrollment_color?.valor_a_pagar
        ) {
          classCss = typeEnrollmentForClassCss.colorDebt.classCss;
        } else {
          if (myEnrollment.have_payment_fee_major) {
            classCss = typeEnrollmentForClassCss.havePaymentFeeMajor.classCss;
          } else {
            if (myEnrollment.last_type_payment_rb) {
              classCss = typeEnrollmentForClassCss.lastTypePaymentRb.classCss;
            } else {
              if (
                  myEnrollment.type_enrollment ===
                  typeEnrollmentForClassCss.mandatory.name &&
                  myEnrollment.enrollment_color?.pay_actually ===
                  typeEnrollmentForClassCss.payActually.name
              ) {
                classCss = typeEnrollmentForClassCss.payActually.classCss;
              }
            }
          }
        }
      }

      return classCss;
    },
    async changeType() {
      this.dialogType = true;
      await raiseMoneyService
          .typeAccount(this.itemSelect.processed, {
            type: this.dialogTypeSelected,
          })
          .then(() => {
            this.itemSelect.type_enrollment = this.dialogTypeSelected;
            this.dialogType = false;
            const summary = "Atención";
            const detail = "Cuenta actualizada correctamente";
            this.notification(typeNotification.INFO, summary, detail);
          })
          .catch((error) => {
            this.dialogType = false;
            const message = error.response.data;
            let errorDetails = this.$utils.formatError(message);
            const summary = "Error";
            const detail = errorDetails;
            this.notification(typeNotification.ERROR, summary, detail);
          });
    },
    getDay(date) {
      return moment(date).format(dateFormats.DD);
    },
    b64EncodeUnicode(sequential) {
      const expression = /%([0-9A-F]{2})/g;
      return btoa(
          encodeURIComponent(sequential).replace(
              expression,
              function (match, p1) {
                const codes = "0x" + p1;
                return String.fromCharCode(codes);
              }
          )
      );
    },
    reload() {
      this.search = "";
      const currentDate = new Date();
      this.dateFinish = null;
      this.dateFinishMax = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() + 1
      );
      this.dateInit = null;
      this.country = null;
      this.subsidiary = null;
      this.executiveAccount = null;
      this.page.page = 1;
      this.typeAccount = null;
      this.dateCobInit = null;
      this.dateCobFinish = null;
      (this.current_fee_status = null), this.filter();
    },
    downloadReport() {
      if (this.country === null) {
        const summary = "Error";
        const detail =
            "Para poder descargar información debes seleccionar un país";
        this.notification(typeNotification.ERROR, summary, detail);
      } else {
        this.loading = true;
        const report = myEnrollmentsReport;
        const config = {
          ...{ q: this.search },
          date_init: this.dateInit
              ? moment(this.dateInit).format(dateFormats.YYYYMMDD)
              : undefined,
          date_finish: this.dateFinish
              ? moment(this.dateFinish).format(dateFormats.YYYYMMDD)
              : undefined,
          date_cob_init: this.dateCobInit
              ? moment(this.dateCobInit).format(dateFormats.YYYYMMDD)
              : undefined,
          date_cob_finish: this.dateCobFinish
              ? moment(this.dateCobFinish).format(dateFormats.YYYYMMDD)
              : undefined,
          country: this.country?.id,
          subsidiary: this.subsidiary?.id,
          executive: this.executiveAccount?.id,
          day_payment: this.day_payment?.id ?? undefined,
          type_account: this.typeAccount ?? undefined,
          current_fee_status: this.current_fee_status ?? undefined,
          report: report,
        };
        raiseMoneyService
            .getMyEnrollments(null, config)
            .then((response) => {
              window.open(response.data.url_file);
            })
            .finally(() => (this.loading = false));
      }
    },
    onPage(event) {
      this.page.page = ++event.page;
      this.loadEnrollments();
    },
    selectData() {
      const to =
          "/raise-money/detail-payments/" +
          this.b64EncodeUnicode(this.currentItem.sequential) +
          "/" +
          this.currentItem.processed;
      this.$router.push(to);
    },
    filter() {
      this.loading = true;
      this.page.page = this.pageInit.page;
      this.loadEnrollments();
    },
    filterEvaluateDates() {
      if (this.dateFinish !== "" && this.dateInit !== "") {
        this.filter();
      }
    },
    filterCobEvaluateDates() {
      if (this.dateCobFinish !== "" && this.dateCobInit !== "") {
        this.filter();
      }
    },
    formatMoney(balancerst) {
      const numberFormat = "en-US";
      const options = {
        style: "currency",
        currency: "USD",
      };
      return new Intl.NumberFormat(numberFormat, options).format(balancerst);
    },
    sumRecaudations(payments) {
      const count = 0;
      return payments
          .map((item) => item.value_paid)
          .reduce((prev, curr) => prev + curr, count);
    },
    loadEnrollments() {
      this.myEnrollments = [];
      this.loading = true;
      const config = {
        ...this.page,
        ...{ q: this.search },
        date_init: this.dateInit
            ? moment(this.dateInit).format(dateFormats.YYYYMMDD)
            : undefined,
        date_finish: this.dateFinish
            ? moment(this.dateFinish).format(dateFormats.YYYYMMDD)
            : undefined,
        date_cob_init: this.dateCobInit
            ? moment(this.dateCobInit).format(dateFormats.YYYYMMDD)
            : undefined,
        date_cob_finish: this.dateCobFinish
            ? moment(this.dateCobFinish).format(dateFormats.YYYYMMDD)
            : undefined,
        country: this.country?.id,
        subsidiary: this.subsidiary?.id,
        executive: this.executiveAccount?.id,
        day_payment: this.day_payment?.id ?? undefined,
        type_account: this.typeAccount ?? undefined,
        current_fee_status: this.current_fee_status ?? undefined,
      };
      raiseMoneyService
          .getMyEnrollments(null, config)
          .then((response) => {
            this.myEnrollments = response.data.result.data;
            this.myEnrollments.map((myEnrollment) => {
              myEnrollment.processeds.agreements = [
                ...myEnrollment?.processeds?.agreements,
              ].shift();
            });
            this.page.total = response.data.result.total;
            this.counter = response.data.counters;
          })
          .finally(() => (this.loading = false));
      monthsWeeksService.getMWSELiq().then((response) => {
        this.countries = response.data;
        if (!this.isAdmin && [this.country]) {
          [this.country] = this.countries;
        }
      });
    },
    getTypePayment(type_payment) {
      if (type_payment === typePayment.INSCRIPTION.name) {
        return typePayment.INSCRIPTION.label;
      } else if (type_payment === typePayment.FEE.name) {
        return typePayment.FEE.label;
      } else {
        return type_payment;
      }
    },
    viewStatus(isUpdate, typeRnrollment) {
      this.dialogTypeSelected = typeRnrollment.type_enrollment;
      this.itemSelect = typeRnrollment;
      this.dialogType = true;
      this.dialogTypeOptions = isUpdate;
    },
  },
  computed: {
    ...mapState("auth", ["isAdmin"]),
    ...mapGetters("bread", ["breadcrumb"]),
    subsidiaries() {
      return this.country ? this.country.subsidiaries : [];
    },
    executives() {
      return this.country ? this.country.executives : [];
    },
    daysPayments() {
      let days = [];
      for (let i = 1; i <= 31; i++) {
        days.push({ id: i, day: i });
      }
      return days;
    },
  },
  mounted() {
    if (this.canAccess("cobranzas_cuentas")) {
      const payload = [
        {
          label: "Lista de Matriculas para gestión de Cobranza",
          url: "javascript:void(0);",
        },
      ];
      const type = "bread/setItems";

      this.$store.dispatch(type, payload);
    }
    monthsWeeksService.getMWSELiq().then((response) => {
      this.countries = response.data;
      if (!this.isAdmin && [this.country]) {
        [this.country] = this.countries;
      } else {
        [this.country] =
            this.countries.filter((country) => {
              const startsWith = "Col";

              if (country.country.startsWith(startsWith)) {
                return country;
              }
            }) ?? [];
      }
      const currentDate = new Date();
      let date = currentDate.getDate();
      this.dateFinishMax = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          ++date
      );
      this.loadEnrollments();
    });
  },
};
